import { Box, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useState, type FC } from 'react';
import { format } from 'date-fns';
import type { EventDetailDTO } from '../api/types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import EventPlatformChip from './EventPlatformChip';

interface EventInfoProps {
  event: EventDetailDTO;
}

const EventInfo: FC<EventInfoProps> = ({ event }) => {
  const [openState, setOpenState] = useState(false);

  return (
    <Paper>
      <Table
        stickyHeader
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Request channel</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{event.status}</TableCell>
            <TableCell>{event.type}</TableCell>
            <TableCell>
              <EventPlatformChip platform={event.request_channel} />
            </TableCell>
            <TableCell>{event.source}</TableCell>
            <TableCell>{format(event.created_at, 'dd.MM.yyyy HH:mm')}</TableCell>
            <TableCell>
              {event.data && (
                <Button
                  size="small"
                  onClick={() => setOpenState(!openState)}
                  endIcon={openState ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                >
                  Expand
                </Button>
              )}
            </TableCell>
          </TableRow>
          {openState && event.data && (
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={6}>
                <Box sx={{ backgroundColor: 'ThreeDFace', overflow: 'auto', maxWidth: '100%' }} padding={2}>
                  <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                    {JSON.stringify(JSON.parse(event.data), null, 2)}
                  </pre>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default EventInfo;
