import { format } from 'date-fns';
import { type FC } from 'react';
import { Paper, Table, TableBody, TableCell, TableRow } from '@mui/material';
import type { EventUserDTO } from '../api/types';

interface EventUserProps {
  user: EventUserDTO;
}

const EventUser: FC<EventUserProps> = ({ user }) => {
  return (
    <Paper>
      <Table
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableBody>
          <TableRow>
            <TableCell variant="head" scope="row">
              ID
            </TableCell>
            <TableCell>{user.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" scope="row">
              App User ID
            </TableCell>
            <TableCell> {user.user_id} </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" scope="row">
              Created At
            </TableCell>
            <TableCell>{format(user.created_at, 'dd.MM.yyyy HH:mm')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head" scope="row">
              Updated At
            </TableCell>
            <TableCell>{format(user.updated_at, 'dd.MM.yyyy HH:mm')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default EventUser;
