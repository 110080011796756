import type { FC } from 'react';
import { Box, Paper, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useGetSkeletonArray } from 'hooks';

const TabledSkeleton: FC = () => {
  const items = useGetSkeletonArray(8);

  return (
    <>
      <Box>
        <Typography variant="overline">
          <Skeleton width={300} />
        </Typography>
        <Box mt={2} />
        <Paper>
          <Table
            stickyHeader
            sx={(theme) => ({
              [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
            })}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Box>

      <Box mt={4}>
        <Typography variant="overline">
          <Skeleton width={300} />
        </Typography>
        <Box mt={2} />
        <Paper>
          <Table
            stickyHeader
            sx={(theme) => ({
              [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
            })}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(({ id }) => (
                <TableRow key={id}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </>
  );
};

export default TabledSkeleton;
