import { Suspense, type FC } from 'react';
import { PageLayout } from 'components';
import { CommonStats } from 'features/stats';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import type { SelectChangeEvent } from '@mui/material';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import TabledSkeleton from 'features/stats/components/TabledSkeleton';

const CommonStatsRoute: FC = () => {
  const [days, setDays] = useQueryParam('days', withDefault(NumberParam, 30));

  const handleSetDays = (event: SelectChangeEvent) => {
    setDays(event.target.value ? parseInt(event.target.value) : 30);
  };

  return (
    <PageLayout name="Business Analytics" outlet>
      <Box pt={2} pb={5}>
        <FormControl>
          <InputLabel>Days</InputLabel>
          <Select label="Days" placeholder="Days" value={`${days}`} onChange={handleSetDays} sx={{ width: 150 }}>
            {[5, 10, 15, 20, 25, 30, 35, 40, 45].map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Suspense fallback={<TabledSkeleton />}>
        <CommonStats days={days} />
      </Suspense>
    </PageLayout>
  );
};

export default CommonStatsRoute;
