import type { FC } from 'react';
import { Chip, Stack, styled, keyframes, Typography, CircularProgress } from '@mui/material';
import { EventStatus } from '../types';
import { darken, lighten } from 'polished';
import { Cancel, CheckCircle, PauseCircleFilled } from '@mui/icons-material';

interface EventStatusChipProps {
  simplify?: boolean;
  status: EventStatus;
}

const statusToLabelPairs = {
  [EventStatus.Completed]: 'completed',
  [EventStatus.Cancelled]: 'cancelled',
  [EventStatus.InProgress]: 'in progress',
  [EventStatus.Pending]: 'pending',
};

const loadingStatusKeyframe = keyframes`
    0% {  opacity: 0.7 }
    100% {  opacity: 1; }
`;

const ChipStyled = styled(Chip)<{ status: EventStatus; simplify?: boolean }>(({ theme, status, simplify }) => ({
  ...(() => {
    switch (status) {
      case EventStatus.Completed:
        return {
          backgroundColor: lighten(0.43, theme.palette.success.light),
          color: theme.palette.success.main,
        };

      case EventStatus.Cancelled:
        return {
          backgroundColor: lighten(0.34, theme.palette.error.light),
          color: theme.palette.error.main,
        };

      case EventStatus.InProgress:
        return {
          backgroundColor: lighten(0.1, theme.palette.info.main),
          color: darken(0.3, theme.palette.info.dark),
          animation: simplify ? '' : `${loadingStatusKeyframe} 0.7s infinite alternate`,
        };

      case EventStatus.Pending:
        return {
          backgroundColor: lighten(0.4, theme.palette.warning.main),
          color: theme.palette.warning.dark,
        };

      default:
        return {};
    }
  })(),
}));

const EventStatusChip: FC<EventStatusChipProps> = ({ simplify, status }) => {
  return simplify ? (
    <Stack alignItems="start">
      <ChipStyled
        simplify={simplify}
        size="small"
        status={status}
        label={
          <Typography m={0} variant="body2" gap={1} display="flex" justifyContent="center" alignItems="center">
            {statusToLabelPairs[status]}
          </Typography>
        }
      />
    </Stack>
  ) : (
    <Stack alignItems="start">
      <ChipStyled
        size="small"
        status={status}
        label={
          <Typography m={0} variant="body2" gap={1} display="flex" justifyContent="center" alignItems="center">
            {statusToLabelPairs[status]}
            {status === EventStatus.InProgress && <CircularProgress size={14} />}
            {status === EventStatus.Pending && <PauseCircleFilled fontSize="small" color="warning" />}
            {status === EventStatus.Completed && <CheckCircle fontSize="small" color="success" />}
            {status === EventStatus.Cancelled && <Cancel fontSize="small" color="error" />}
          </Typography>
        }
      />
    </Stack>
  );
};

export default EventStatusChip;
