import type { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useGetEvents } from '../api';
import { format } from 'date-fns';
import { useProjectContext } from 'features/projects';
import EventStatusChip from './EventStatusChip';
import EventPlatformChip from './EventPlatformChip';

interface EventsListProps {
  type: string;
  status: string;
  requestChannel: string;
  source: string;
  email: string;
  appUserId: string | undefined;
  webUserId: string | undefined;
  eventId: string | undefined;
  operationId: string | undefined;
}

const EventsList: FC<EventsListProps> = ({
  type,
  status,
  requestChannel,
  source,
  email,
  appUserId,
  webUserId,
  eventId,
  operationId,
}) => {
  const { project } = useProjectContext();

  const events = useGetEvents({
    params: {
      project: project.slug,
      type: type || undefined,
      status: status || undefined,
      request_channel: requestChannel || undefined,
      source: source || undefined,
      email: email || undefined,
      app_user_id: appUserId || undefined,
      web_user_id: webUserId || undefined,
      event_id: eventId || undefined,
      operation_id: operationId || undefined,
    },
  });

  return (
    <TableContainer>
      <Table
        stickyHeader
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 100 }}>ID</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell sx={{ width: 100 }}>Source</TableCell>
            <TableCell>Request Channel</TableCell>
            <TableCell>User ID</TableCell>
            <TableCell>Created</TableCell>
          </TableRow>
        </TableHead>
        {events.data?.length ? (
          <TableBody>
            {events.data.map((event) => (
              <TableRow key={event.id}>
                <TableCell>
                  <Link component={RouterLink} to={`${event.id}`}>
                    {event.id}
                  </Link>
                </TableCell>
                <TableCell>{event.type}</TableCell>
                <TableCell>
                  <EventStatusChip status={event.status} simplify />
                </TableCell>
                <TableCell>{event.source}</TableCell>
                <TableCell>
                  <EventPlatformChip platform={event.request_channel} />
                </TableCell>
                <TableCell>{event.user_id}</TableCell>
                <TableCell>{event.created_at ? format(event.created_at, 'dd.MM.yyyy HH:mm') : ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={7}>
                <Stack alignItems="center" justifyContent="center" p={5}>
                  <Typography>Nothing found. Adjust your filters and try again.</Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default EventsList;
