import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useGetStats } from '../api';
import { EventsDuration, StackedBar } from '.';
import OperationDuration from './OperationsDuration';
import { Bar } from 'react-chartjs-2';
import { useProjectContext } from 'features/projects';

interface StatsPageProps {
  days: number;
}

const CommonStats: FC<StatsPageProps> = ({ days }) => {
  const { project } = useProjectContext();
  const { data: stats } = useGetStats({ params: { days, project: project.slug } });

  return (
    <>
      <Box>
        <Typography variant="overline">Events Duration Stats</Typography>
        <Box mt={2} />
        <EventsDuration data={stats.event_duration} />
      </Box>

      <Box mt={4}>
        <Typography variant="overline">Operation Duration Stats</Typography>
        <Box mt={2} />
        <OperationDuration data={stats.operation_duration} />
      </Box>

      <Box mt={4}>
        <Typography variant="overline">Services statuses</Typography>
        <Box mt={2} />
        <StackedBar title={stats.services.label} dataset={stats.services.datasets} />
      </Box>

      <Box mt={4}>
        <Typography variant="overline">Request channel</Typography>
        <Box mt={2} />
        <Bar
          height={80}
          data={stats.request_channel}
          options={{
            plugins: { title: { display: true, text: `${stats.request_channel.label}` }, legend: { display: false } },
            responsive: true,
            parsing: { xAxisKey: 'count', yAxisKey: 'key' },
            indexAxis: 'y',
          }}
        />
      </Box>
    </>
  );
};

export default CommonStats;
