import type { FC } from 'react';
import { set } from 'lodash-es';
import { AppLocales } from 'locales';
import { useGetTemplates } from '../api';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TemplateRow from './TemplateRow';
import type { TemplateDTO, TemplatesParams } from '../api/types';
import { TemplateTypes } from '../api/types';
import { useProjectContext } from 'features/projects';

interface ProjectListProps {
  filters: Omit<TemplatesParams, 'project'>;
}

const ProjectList: FC<ProjectListProps> = ({ filters }) => {
  const { project } = useProjectContext();

  const { data: templates } = useGetTemplates({
    params: { ...filters, project: project.slug },
  });

  const projectsMap = [{ slug: project.slug }];

  const typesMap = filters.type
    ? [filters.type]
    : [TemplateTypes.deletion_request_confirmed, TemplateTypes.deletion_request_completed];

  const localesMap = filters.language ? [filters.language] : Object.keys(AppLocales);

  const templatesPlaceholderData = localesMap.reduce<
    Array<TemplateDTO | Pick<TemplateDTO, 'project' | 'language' | 'type'>>
  >((memo, locale) => {
    type Rel = Record<string, Record<string, Record<string, TemplateDTO>>>;

    const rel = templates.reduce<Rel>((result, temp) => {
      set(result, `${temp.project.slug}.${temp.language}.${temp.type}`, temp);
      return result;
    }, {});

    projectsMap.forEach((prj) => {
      typesMap.forEach((tempType) => {
        memo.push(
          rel[prj.slug]?.[locale]?.[tempType] ?? {
            project: prj,
            language: locale,
            type: tempType,
          }
        );
      });
    });

    return memo;
  }, []);

  return (
    <Table
      stickyHeader
      sx={(theme) => ({
        [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
      })}
    >
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: 80 }}>ID</TableCell>
          <TableCell sx={{ width: 220 }}>Type</TableCell>
          <TableCell>Language</TableCell>
          <TableCell>Created at</TableCell>
          <TableCell>Updated at</TableCell>
          <TableCell sx={{ width: 140 }}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {templatesPlaceholderData.map((tmp) => (
          <TemplateRow key={tmp.language + tmp.type} data={tmp} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ProjectList;
