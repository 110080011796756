import { type FC, Suspense } from 'react';
import { DrawerPage } from 'components';
import TemplateDetails from 'features/templates/components/TemplateDetails';
import TemplateSkeleton from 'features/templates/components/TemplateSkeleton';

const TemplateDetailRoute: FC = () => {
  return (
    <DrawerPage halfSize>
      <Suspense fallback={<TemplateSkeleton />}>
        <TemplateDetails />
      </Suspense>
    </DrawerPage>
  );
};

export default TemplateDetailRoute;
