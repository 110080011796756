import { Box, Button, TableCell, TableRow } from '@mui/material';
import { useState, type FC } from 'react';
import { format } from 'date-fns';
import type { LogDTO } from '../api/types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

interface EventLogRowProps {
  data: LogDTO;
}

const EventLogRow: FC<EventLogRowProps> = ({ data }) => {
  const [openPayload, setOpenPayload] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>{data.id}</TableCell>
        <TableCell>{data.status}</TableCell>
        <TableCell>{data.message}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{format(data.created_at, 'dd.MM.yyyy HH:mm')}</TableCell>
        <TableCell>{data.step_id}</TableCell>
        <TableCell>{data.operation_id}</TableCell>
        <TableCell>
          {data.data && (
            <Button
              size="small"
              onClick={() => setOpenPayload(!openPayload)}
              endIcon={openPayload ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            >
              Expand
            </Button>
          )}
        </TableCell>

        <TableCell>{data.skip_counter}</TableCell>
        <TableCell>{data.exec_counter}</TableCell>
      </TableRow>
      {openPayload && data.data && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={9}>
            <Box sx={{ backgroundColor: 'ThreeDFace', overflow: 'auto', maxWidth: '100%' }} padding={2}>
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                {JSON.stringify(JSON.parse(data.data), null, 2)}
              </pre>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default EventLogRow;
