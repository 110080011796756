import {
  Logout as LogoutIcon,
  BarChartOutlined as BarChartOutlinedIcon,
  EventNoteOutlined as EventNoteIcon,
  HistoryOutlined as HistoryIcon,
  QueryStatsOutlined as QueryStatsIcon,
  Markunread,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  ThemeProvider,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import type { FC } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { useAuth } from 'lib/auth/AuthContext';
import { darkSubTheme } from 'theme';
import config from 'config';
import SidebarNavItem from '../../SidebarNavItem';
import ProjectSelector from 'features/projects/components/ProjectSelector';
import { useProjectContext } from 'features/projects/components/ProjectContextProvider';

const HomeLink = styled(Link)`
  color: ${({ theme }) => theme.palette.info.light};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const EnvLabel = styled(Box)`
  z-index: 100;
  color: black;
  text-align: center;
  font-weight: 600;
  top: 0;
  left: 0;
  right: 0;
  font-size: 12px;
  padding: 1px 6px;
  margin-left: 5px;
  border-radius: 12px;
  background-color: #efef62;
  text-transform: uppercase;
  letter-spacing: initial;
`;

interface SidebarProps {
  isShown: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const Sidebar: FC<SidebarProps> = ({ isShown, openDrawer, closeDrawer }) => {
  const theme = useTheme();
  const { project, setProject } = useProjectContext();
  const isStage = config.env.ENVIRONMENT === 'stage';

  const isStatsPage = useMatch(`/${project.slug}/stats/*`);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAuth();

  const handleSignOut = () => {
    signOut();
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        pt: 1.3,
      }}
    >
      <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <HomeLink to="/">
          {config.title}
          {isStage && <EnvLabel>stage</EnvLabel>}
        </HomeLink>
      </Toolbar>

      <Box component="nav" sx={{ flexGrow: 1 }}>
        <List>
          <SidebarNavItem icon={<QueryStatsIcon />} title="Analytics" initialExpanded={!!isStatsPage}>
            <SidebarNavItem
              to={`/${project.slug}/stats/general`}
              icon={<BarChartOutlinedIcon />}
              title="Business Analytics"
            />
            <SidebarNavItem
              to={`/${project.slug}/stats/records`}
              icon={<BarChartOutlinedIcon />}
              title="Records Stats"
            />
            <SidebarNavItem to={`/${project.slug}/stats/hourly`} icon={<HistoryIcon />} title="Hourly Stats" />
          </SidebarNavItem>
          <SidebarNavItem to={`/${project.slug}/templates/list`} icon={<Markunread />} title="Templates" />
          <SidebarNavItem to={`/${project.slug}/events`} icon={<EventNoteIcon />} title="Events" />
        </List>
      </Box>
      <Box sx={{ py: 3 }}>
        <Box px={2}>
          <ProjectSelector value={project.slug} emptyValueLabel={null} onChange={setProject} />
        </Box>
        <List>
          <ListItem>
            <Divider />
            <ListItemText
              primary={user?.name}
              secondary={user?.email}
              secondaryTypographyProps={{ noWrap: true }}
              sx={(t) => ({ padding: `${t.spacing(2)} 0 0`, wordBreak: 'break-all' })}
            />
          </ListItem>
          <SidebarNavItem icon={<LogoutIcon />} onClick={handleSignOut} title="Sign Out" />
        </List>
      </Box>
    </Box>
  );

  if (isMobile) {
    return (
      <ThemeProvider theme={darkSubTheme}>
        <SwipeableDrawer
          variant="temporary"
          sx={{ zIndex: theme.zIndex.appBar - 1, maxHeight: '100vh' }}
          PaperProps={{
            sx: {
              height: 'unset',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              maxWidth: theme.sizes.sidebarWidth,
              [theme.breakpoints.down('md')]: {
                // top: 65,
                maxWidth: '40%',
              },
              [theme.breakpoints.down('sm')]: {
                // top: 57,
                maxWidth: '100vw',
              },
            },
          }}
          componentsProps={{
            backdrop: () => ({
              sx: {
                [theme.breakpoints.down('md')]: {
                  top: 65,
                },
                [theme.breakpoints.down('sm')]: {
                  top: 57,
                },
              },
            }),
          }}
          open={isShown}
          onOpen={openDrawer}
          onClose={closeDrawer}
        >
          <Toolbar />
          {content}
        </SwipeableDrawer>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkSubTheme}>
      <Drawer
        variant="permanent"
        sx={{
          flexShrink: 0,
          width: theme.sizes.sidebarWidth,
        }}
        PaperProps={{
          sx: {
            width: theme.sizes.sidebarWidth,
          },
        }}
      >
        {content}
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;
