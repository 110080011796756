import type { FC } from 'react';
import { Box, LinearProgress, styled } from '@mui/material';
import { useGetSkeletonArray } from 'hooks';
import { Bar } from 'react-chartjs-2';

const LinearProgressStyled = styled(LinearProgress)`
  position: absolute;
  bottom: 58px;
  left: 50px;
  right: 0;
`;

const BarSkeleton: FC = () => {
  const items = useGetSkeletonArray(60);

  return (
    <Box position="relative">
      <LinearProgressStyled color="secondary" />
      <Bar
        data={{
          labels: items.map(() => 'Loading'),
          datasets: [
            {
              label: 'Loading',
              data: items,
            },
          ],
        }}
        options={{
          plugins: { title: { display: true, text: 'Loading' } },
          responsive: true,
          scales: { y: { stacked: true, max: 10000 } },
        }}
      />
    </Box>
  );
};

export default BarSkeleton;
