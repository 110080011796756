import { type FC, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { PageLayout } from 'components';
import HeadLink from 'components/HeadLink';
import { useProjectContext } from 'features/projects';

const TemplatesLayoutRoute: FC = () => {
  const { project } = useProjectContext();

  const nameSwitcher = useMemo(() => {
    return (
      <Typography variant="h5" textTransform="capitalize" fontWeight="bold">
        <Stack direction="row" alignItems="center" gap={3}>
          <HeadLink to={`/${project.slug}/templates/list`}>Templates</HeadLink>
          <HeadLink to={`/${project.slug}/templates/placeholders`}>Placeholders</HeadLink>
        </Stack>
      </Typography>
    );
  }, []);

  return <PageLayout name={nameSwitcher} outlet />;
};

export default TemplatesLayoutRoute;
