import { useState } from 'react';
import type { FC } from 'react';
import { format } from 'date-fns';
import { Box, Button, IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import type { OpertionDTO } from '../api/types';
import { KeyboardArrowDown, KeyboardArrowUp, MarkEmailRead, Unsubscribe } from '@mui/icons-material';
import KeyValuePrettifier from 'components/KeyValuePrettifier';
import EventStatusChip from './EventStatusChip';
import OperationEmailLog from './OperationEmailLog';

interface OperationRowProps {
  data: OpertionDTO;
}

const OperationRow: FC<OperationRowProps> = ({ data }) => {
  const [openState, setOpenState] = useState(false);
  const [openEmailLogs, setOpenEmailLogs] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>{data.id}</TableCell>
        <TableCell>{data.service}</TableCell>
        <TableCell>
          <Stack direction="row" flexWrap="wrap" alignItems="center" gap={2}>
            <EventStatusChip status={data.status} />
          </Stack>
        </TableCell>
        <TableCell>{data.identifier ? <KeyValuePrettifier data={data.identifier} /> : '—'}</TableCell>
        <TableCell align="center" padding="none">
          {data.state && (
            <Button
              size="small"
              onClick={() => setOpenState(!openState)}
              endIcon={openState ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            >
              State
            </Button>
          )}

          {data.action === 'notify' && data.email_log?.status === 'sent' && (
            <Tooltip title="The email has been successfully sent">
              <IconButton onClick={() => setOpenEmailLogs(true)}>
                <MarkEmailRead color="success" />
              </IconButton>
            </Tooltip>
          )}

          {data.action === 'notify' && data.email_log?.status === 'not_sent' && (
            <Tooltip title="The email hasn't been sent">
              <IconButton onClick={() => setOpenEmailLogs(true)}>
                <Unsubscribe color="error" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>{data.last_executed_at ? format(data.last_executed_at, 'dd.MM.yy HH:mm') : 'Not yet'}</TableCell>
        <TableCell>{format(data.created_at, 'dd.MM.yy HH:mm')}</TableCell>
        <TableCell>{format(data.updated_at, 'dd.MM.yy HH:mm')}</TableCell>
      </TableRow>
      {openState && data.state && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={8}>
            <Box sx={{ backgroundColor: 'ThreeDFace', overflow: 'auto', maxWidth: '100%' }} padding={2}>
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                {JSON.stringify(JSON.parse(data.state), null, 2)}
              </pre>
            </Box>
          </TableCell>
        </TableRow>
      )}
      {openEmailLogs && <OperationEmailLog data={data.email_log} onClose={() => setOpenEmailLogs(false)} />}
    </>
  );
};

export default OperationRow;
