import { apiClient } from 'api';
import type { EventDTO, EventsQueryParams, EventDetailDTO, EventLogsDTO } from './types';
import { useSuspenseQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import type { ProjectDTO } from 'features/projects/api/types';

export const getProjects = async (signal?: AbortSignal): Promise<ProjectDTO[]> => {
  const res = await apiClient.get<ProjectDTO[]>('/admin/projects', { signal });

  return res.data;
};

export const getEvents = async (params: EventsQueryParams, signal?: AbortSignal): Promise<EventDTO[]> => {
  const res = await apiClient.get<EventDTO[]>('/admin/events', { params, signal });

  return res.data;
};

export const getEvent = async (eventId: string, signal?: AbortSignal): Promise<EventDetailDTO> => {
  // await new Promise((r) => setTimeout(r, 50000000));
  const res = await apiClient.get<EventDetailDTO>(`/admin/events/${eventId}`, { signal });

  return res.data;
};

export const getEventLogs = async (eventId: string, signal?: AbortSignal): Promise<EventLogsDTO> => {
  const res = await apiClient.get(`/admin/events/${eventId}/logs`, { signal });

  return res.data;
};

export const useGetProjects = () => {
  return useSuspenseQuery({
    retry: false,
    queryFn: ({ signal }) => getProjects(signal),
    queryKey: queryKeys.projectsList(),
  });
};

export type UseEventsQueryParams = {
  params: EventsQueryParams;
};

export const useGetEvents = ({ params }: UseEventsQueryParams) => {
  return useSuspenseQuery({
    retry: false,
    queryFn: ({ signal }) => getEvents(params, signal),
    queryKey: queryKeys.eventsList(params),
  });
};

export const useGetEvent = (eventId: string) => {
  return useSuspenseQuery({
    retry: false,
    queryFn: ({ signal }) => getEvent(eventId, signal),
    queryKey: queryKeys.eventDetail(eventId),
  });
};

export const useGetEventLogs = (eventId: string) => {
  return useSuspenseQuery({
    retry: false,
    queryFn: ({ signal }) => getEventLogs(eventId, signal),
    queryKey: queryKeys.logs(eventId),
  });
};
