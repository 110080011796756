import type { ProjectDTO } from 'features/projects/api/types';
import type { AppLocales } from 'locales';

export enum TemplateTypes {
  deletion_request_completed = 'deletion_request_completed',
  deletion_request_confirmed = 'deletion_request_confirmed',
}

export type TemplatesParams = {
  type: string | undefined;
  language: string | undefined;
  project: string | undefined;
};

export interface TemplateDTO {
  id: number;
  language: keyof typeof AppLocales;
  subject: string;
  text: string;
  type: TemplateTypes;
  html: string | null;
  created_at: string;
  updated_at: string;
  project: ProjectDTO;
}

export interface TemplatePayload {
  language: string;
  project: string;
  subject: string;
  text: string;
  type: string;
  html: string;
}
