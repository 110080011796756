import { type FC } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import objectTypedKeys from 'utils/objectTypedKeys';
import { AppLocales } from 'locales';

const LocaleSelector: FC<{
  value: string;
  onChange?: (val: string) => void;
  nativeOnChange?: (e: SelectChangeEvent) => void;
  emptyValueLabel?: string | null;
  error?: string;
  disabled?: boolean;
}> = ({ value, disabled, emptyValueLabel = 'All locales', error, nativeOnChange, onChange }) => {
  const handleLocaleChange = (event: SelectChangeEvent) => {
    onChange?.(event.target.value);
    nativeOnChange?.(event);
  };

  return (
    <FormControl sx={{ width: '100%' }} error={!!error}>
      <InputLabel>Locale</InputLabel>
      <Select label="Locale" placeholder="Locale" value={value} onChange={handleLocaleChange} disabled={disabled}>
        {emptyValueLabel === null ? null : <MenuItem value={undefined}>{emptyValueLabel}</MenuItem>}
        {objectTypedKeys(AppLocales).map((localeKey) => (
          <MenuItem key={localeKey} value={localeKey}>
            {AppLocales[localeKey]}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default LocaleSelector;
