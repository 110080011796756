export enum AppLocales {
  en = 'English',
  uk = 'Ukrainian',
  es = 'Spanish',
  pt = 'Portuguese',
  fr = 'French',
  it = 'Italian',
  de = 'German',
  tr = 'Turkish',
  ja = 'Japanese',
  ko = 'Korean',
  no = 'Norwegian',
  sv = 'Swedish',
  da = 'Danish',
  nl = 'Dutch',
  zh_hans = 'Chinese (Simplified)',
  zh_hant = 'Chinese (Traditional)',
  ru = 'Russian',
  ar = 'Arabic',
}
