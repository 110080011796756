import * as yup from 'yup';

export const templateFormValidationSchema = yup.object({
  language: yup.string().required(),
  type: yup.string().required(),
  project: yup.string().required(),
  subject: yup.string().min(3).required(),
  text: yup.string().min(3).required(),
  html: yup.string().default(''),
});
