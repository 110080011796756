import { useGetEvent } from '../api';
import type { FC } from 'react';
import EventUser from './EventUser';
import EventInfo from './EventInfo';
import EventOperations from './EventOperations';
import { Box, Stack, Typography } from '@mui/material';
import EventUserEntries from './EventUserEntries';

interface EventDetailProps {
  eventId: string;
}

const EventDetail: FC<EventDetailProps> = ({ eventId }) => {
  const { data } = useGetEvent(eventId);

  return (
    <>
      {data.user && (
        <Stack direction="row" gap={2}>
          <Box flexGrow={1}>
            <Typography variant="overline">User Information</Typography>
            <Box mt={2} />
            <EventUser user={data.user} />
          </Box>
          {!!data.user.entries?.length && (
            <Box flexGrow={1} minWidth="60%">
              <Typography variant="overline"> Data Entries</Typography>
              <Box mt={2} />
              <EventUserEntries data={data.user.entries} />
            </Box>
          )}
        </Stack>
      )}

      <Box sx={{ '&:not(:first-child)': { mt: 4 } }}>
        <Typography variant="overline">Event Information</Typography>
        <Box mt={2} />
        <EventInfo event={data} />
      </Box>

      <Box mt={4}>
        <Typography variant="overline">Operations Information</Typography>
        <Box mt={2} />
        <EventOperations steps={data.steps} />
      </Box>
    </>
  );
};

export default EventDetail;
