import { type FC } from 'react';
import { useGetProjects } from 'features/events';
import type { SelectChangeEvent } from '@mui/material';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import withSuspense from 'utils/withSuspense';

const ProjectSelector: FC<{
  value: string | undefined;
  onChange?: (val: string) => void;
  nativeOnChange?: (e: SelectChangeEvent) => void;
  emptyValueLabel?: string | null;
  error?: string;
  disabled?: boolean;
}> = ({ value, disabled, error, emptyValueLabel = 'All projects', nativeOnChange, onChange }) => {
  const projects = useGetProjects();

  const handleProjectChange = (event: SelectChangeEvent) => {
    onChange?.(event.target.value);
    nativeOnChange?.(event);
  };

  return (
    <FormControl sx={{ width: '100%' }} error={!!error}>
      <InputLabel>Project</InputLabel>
      <Select label="Project" placeholder="Project" value={value} onChange={handleProjectChange} disabled={disabled}>
        {emptyValueLabel === null ? null : <MenuItem value={undefined}>{emptyValueLabel}</MenuItem>}
        {projects.data.map((project) => (
          <MenuItem key={project.id} value={project.slug}>
            {project.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default withSuspense(ProjectSelector, <Skeleton height={56} variant="rounded" />);
