import { type FC } from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import type { OperationDurationDTO } from '../api/types';
import formatSeconds from 'utils/formatSeconds';

interface OperationDurationProps {
  data: OperationDurationDTO;
}

const OperationDuration: FC<OperationDurationProps> = ({ data }) => {
  return (
    <Paper>
      <Table
        stickyHeader
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Service</TableCell>
            <TableCell align="center">Min</TableCell>
            <TableCell align="center">Max</TableCell>
            <TableCell align="center">Avg</TableCell>
            <TableCell align="center">P95</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.operations.map((duration, index) => (
            <TableRow key={`opr-${index}`}>
              <TableCell align="center">{duration.name}</TableCell>
              <TableCell align="center">{formatSeconds(duration.min)}</TableCell>
              <TableCell align="center">{formatSeconds(duration.max)}</TableCell>
              <TableCell align="center">{formatSeconds(duration.avg)}</TableCell>
              <TableCell align="center">{formatSeconds(duration.p_95)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default OperationDuration;
