import { Box } from '@mui/material';
import { useGetProjects } from 'features/events';
import { useSnackbar } from 'notistack';
import { type FC } from 'react';
import { Navigate, useMatch, Outlet } from 'react-router';
import useTypedParams from 'utils/useTypedParams';

const ProjectSlugRoute: FC<{ to: (slug: string) => string }> = ({ to }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: projects } = useGetProjects();
  const { projectSlug } = useTypedParams({ projectSlug: String });
  const shouldBeRedirected = !!useMatch({ path: ':projectSlug', end: true });

  if (!projects.find(({ slug }) => slug === projectSlug)) {
    enqueueSnackbar(
      <Box>
        The URL is out of date, not valid or you have lost access to the project.
        <br />
        You have been redirected to the first project available to you.
      </Box>,
      {
        variant: 'info',
        autoHideDuration: 5000,
      }
    );
    return <Navigate to={to(projects[0]?.slug)} replace />;
  }

  if (shouldBeRedirected) {
    return <Navigate to={to(projectSlug)} replace />;
  } else {
    return <Outlet />;
  }
};

export default ProjectSlugRoute;
