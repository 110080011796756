import { Suspense, type FC } from 'react';
import { PageLayout } from 'components';
import { HourlyStats } from 'features/stats';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import type { SelectChangeEvent } from '@mui/material';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import BarSkeleton from 'features/stats/components/BarSkeleton';

const HourlyStatsRoute: FC = () => {
  const [days, setDays] = useQueryParam('days', withDefault(NumberParam, 30));

  const handleSetDays = (event: SelectChangeEvent) => {
    setDays(event.target.value ? parseInt(event.target.value) : 30);
  };

  return (
    <PageLayout name="Hourly Stats" outlet>
      <Box py={2}>
        <FormControl>
          <InputLabel>Days</InputLabel>
          <Select label="Days" placeholder="Days" value={`${days}`} onChange={handleSetDays} sx={{ width: 150 }}>
            {[5, 10, 15, 20, 25, 30, 35, 40, 45].map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Suspense fallback={<BarSkeleton />}>
        <HourlyStats days={days} />
      </Suspense>
    </PageLayout>
  );
};

export default HourlyStatsRoute;
