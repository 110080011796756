import type { FC, ReactNode} from 'react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Titled } from 'react-titled';
import { createContext, useContext } from 'react';
import useTypedParams from 'utils/useTypedParams';
import { useGetProjects } from '../api';
import type { ProjectDTO } from '../api/types';

export interface ProjectContextType {
  project: ProjectDTO;
  setProject(project: string): void;
}

const ProjectContext = createContext<ProjectContextType>({} as ProjectContextType);

export const useProjectContext = () => {
  return useContext(ProjectContext);
};

export { ProjectContext };

const ProjectContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const { data: projects } = useGetProjects();
  const { projectSlug } = useTypedParams({ projectSlug: String });

  const project = projects.find(({ slug }) => slug === projectSlug) ?? projects[0];

  const setProject = (slug: string) => {
    navigate(`/${slug}`);
  };

  const contextValue = useMemo(
    () => ({
      project,
      setProject,
    }),
    [project, setProject]
  );

  return (
    <ProjectContext.Provider value={contextValue}>
      <Titled title={(title) => `${project.name} | ${title}`}>{children}</Titled>
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
