import { Suspense, type FC } from 'react';
import { ListSkeleton, PageLayout, TableListErrorBoundary } from 'components';
import { EventStatus, EventSource, EventType, EventsList, EventRequestChannel } from 'features/events';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { capitalize, replace } from 'lodash-es';
import { useSearch } from 'hooks';
import { Close } from '@mui/icons-material';

const EventListRoute: FC = () => {
  const [type, setType] = useQueryParam('type', withDefault(StringParam, ''));
  const [status, setStatus] = useQueryParam('status', withDefault(StringParam, ''));
  const [source, setSource] = useQueryParam('source', withDefault(StringParam, ''));
  const [requestChannel, setRequestChannel] = useQueryParam('rch', withDefault(StringParam, ''));

  const [appUserId, appUserIdValue, appUserIdHandler, appUserIdReset] = useSearch('app_user_id');
  const [webUserId, webUserIdValue, webUserIdHandler, webUserIdReset] = useSearch('web_user_id');
  const [email, emailValue, emailHandler, emailReset] = useSearch('email');
  const [eventId, eventIdValue, eventIdHandler, eventIdReset] = useSearch('event_id');
  const [operationId, operationIdValue, operationIdHandler, operationIdReset] = useSearch('operation_id');

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleSourceChange = (event: SelectChangeEvent) => {
    setSource(event.target.value);
  };

  const handleRequestChannelChange = (event: SelectChangeEvent) => {
    setRequestChannel(event.target.value);
  };

  return (
    <PageLayout name="Events">
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box p={2}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={3}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel>Status</InputLabel>
                <Select label="Status" placeholder="Status" value={status} onChange={handleStatusChange}>
                  <MenuItem value="">All Statuses</MenuItem>
                  {Object.values(EventStatus).map((value) => (
                    <MenuItem key={value} value={value}>
                      {capitalize(replace(value, '_', ' '))}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel>Type</InputLabel>
                <Select label="Type" placeholder="Type" value={type} onChange={handleTypeChange}>
                  <MenuItem value="">All types</MenuItem>
                  {Object.values(EventType).map((value) => (
                    <MenuItem key={value} value={value}>
                      {capitalize(replace(value, '_', ' '))}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel>Source</InputLabel>
                <Select label="Source" placeholder="Source" value={source} onChange={handleSourceChange}>
                  <MenuItem value="">All sources</MenuItem>
                  {Object.values(EventSource).map((value) => (
                    <MenuItem key={value} value={value}>
                      {capitalize(replace(value, '_', ' '))}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel>Request channel</InputLabel>
                <Select
                  value={requestChannel}
                  label="Request channel"
                  placeholder="Request channel"
                  onChange={handleRequestChannelChange}
                >
                  <MenuItem value="">All channels</MenuItem>
                  {Object.values(EventRequestChannel).map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                label="App User ID"
                placeholder="App User ID"
                onChange={appUserIdHandler}
                value={appUserIdValue}
                InputProps={{
                  endAdornment: appUserId ? (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={appUserIdReset}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Web User ID"
                placeholder="Web User ID"
                onChange={webUserIdHandler}
                value={webUserIdValue}
                InputProps={{
                  endAdornment: webUserId ? (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={webUserIdReset}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Email"
                placeholder="Email"
                onChange={emailHandler}
                value={emailValue}
                InputProps={{
                  endAdornment: email ? (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={emailReset}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Event ID"
                placeholder="Event ID"
                onChange={eventIdHandler}
                value={eventIdValue}
                InputProps={{
                  endAdornment: eventId ? (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={eventIdReset}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Operation ID"
                placeholder="Operation ID"
                onChange={operationIdHandler}
                value={operationIdValue}
                InputProps={{
                  endAdornment: operationId ? (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={operationIdReset}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <TableListErrorBoundary>
          <Suspense
            fallback={
              <ListSkeleton
                typographyMode
                titles={[
                  { field: 'ID', width: 100 },
                  { field: 'Type' },
                  { field: 'Status' },
                  { field: 'Source', width: 100 },
                  { field: 'Request Channel' },
                  { field: 'User ID' },
                  { field: 'Created' },
                ]}
                size={10}
              />
            }
          >
            <EventsList
              type={type}
              status={status}
              requestChannel={requestChannel}
              source={source}
              webUserId={webUserId}
              appUserId={appUserId}
              email={email}
              eventId={eventId}
              operationId={operationId}
            />
          </Suspense>
        </TableListErrorBoundary>
      </Paper>
    </PageLayout>
  );
};

export default EventListRoute;
