import type { FC } from 'react';
import type { DurationDTO } from '../api/types';
import formatSeconds from 'utils/formatSeconds';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

interface EventDurationProps {
  data: DurationDTO;
}

const EventsDuration: FC<EventDurationProps> = ({ data }) => {
  return (
    <Paper>
      <Table
        stickyHeader
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Min</TableCell>
            <TableCell align="center">Max</TableCell>
            <TableCell align="center">Avg</TableCell>
            <TableCell align="center">P95</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">{formatSeconds(data.min)}</TableCell>
            <TableCell align="center">{formatSeconds(data.max)}</TableCell>
            <TableCell align="center">{formatSeconds(data.avg)}</TableCell>
            <TableCell align="center">{formatSeconds(data.p_95)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default EventsDuration;
