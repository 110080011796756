import type { FC } from 'react';
import { Box } from '@mui/material';
import { useGetHourlyStats } from '../api';
import { StackedBar } from '../components';
import { useProjectContext } from 'features/projects';

interface HourlyStatsProps {
  days: number;
}

const HourlyStats: FC<HourlyStatsProps> = ({ days }) => {
  const { project } = useProjectContext();
  const { data } = useGetHourlyStats({ params: { days, project: project.slug } });

  return (
    <Box>
      {data.hourly.map((hourly, index) => (
        <Box key={`hrl=${index}`} mb={6}>
          <StackedBar title={hourly.label} dataset={hourly.datasets} />
        </Box>
      ))}
    </Box>
  );
};

export default HourlyStats;
