import type { FC } from 'react';
import {
  Box,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useGetSkeletonArray } from 'hooks';

const TypographySkeleton: FC = () => (
  <Typography>
    <Skeleton />
  </Typography>
);

const EventDetailSkeleton: FC = () => {
  const ind4 = useGetSkeletonArray(4);
  const ind5 = useGetSkeletonArray(5);
  const ind6 = useGetSkeletonArray(6);

  return (
    <>
      <Stack direction="row" gap={2}>
        <Box flexGrow={1}>
          <Typography variant="overline">
            <Skeleton width={300} />
          </Typography>
          <Box mt={2} />
          <Paper>
            <Table
              sx={(theme) => ({
                [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
              })}
            >
              <TableBody>
                {ind4.map(({ id }) => (
                  <TableRow key={id}>
                    <TableCell>
                      <TypographySkeleton />
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <TypographySkeleton />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>

        <Box flexGrow={1} minWidth="60%">
          <Typography variant="overline">
            <Skeleton width={300} />
          </Typography>
          <Box mt={2} />
          <Paper>
            <Table
              stickyHeader
              sx={(theme) => ({
                [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
              })}
            >
              <TableHead>
                <TableRow>
                  {ind5.map(({ id }) => (
                    <TableCell key={id}>
                      <TypographySkeleton />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {ind5.map(({ id }) => (
                    <TableCell key={id}>
                      <TypographySkeleton />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Box>
      </Stack>

      <Box mt={4}>
        <Typography variant="overline">
          <Skeleton width={300} />
        </Typography>
        <Box mt={2} />
        <Paper>
          <Table
            stickyHeader
            sx={(theme) => ({
              [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
            })}
          >
            <TableHead>
              <TableRow>
                {ind6.map(({ id }) => (
                  <TableCell key={id}>
                    <TypographySkeleton />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ind4.map(({ id }) => (
                <TableRow key={id}>
                  {ind6.map(({ id: idInside }) => (
                    <TableCell key={idInside}>
                      <TypographySkeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </>
  );
};

export default EventDetailSkeleton;
