import type { FC } from 'react';
import { format } from 'date-fns';
import { Box, Link, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import type { OpertionEmailLogDTO } from '../api/types';
import { DrawerPage, SidePageLayout } from 'components';
import { Link as RouterLink } from 'react-router-dom';

interface OperationEmailLogProps {
  data: OpertionEmailLogDTO;
  onClose: () => void;
}

const OperationEmailLog: FC<OperationEmailLogProps> = ({ onClose, data }) => {
  const renders = data.rendered ? JSON.parse(data.rendered) : null;

  const errors = (() => {
    try {
      const errorData = JSON.parse(data.validation_errors);
      if (!Object.keys(errorData).length) return null;

      return errorData;
    } catch {
      return null;
    }
  })();

  return (
    <DrawerPage halfSize skipNavagation onClose={onClose}>
      <SidePageLayout closeButton={null} fullHeight title={null} name={`Email log ${data.id}`}>
        <Box>
          <Typography variant="overline">Email log</Typography>
          <Paper>
            <Table
              sx={(theme) => ({
                [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
              })}
            >
              <TableBody>
                <TableRow>
                  <TableCell width="30%" variant="head">
                    ID:
                  </TableCell>
                  <TableCell variant="body">{data.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" variant="head">
                    Template:
                  </TableCell>
                  <TableCell variant="body">
                    <Link component={RouterLink} to={`../../templates/list/${data.template_id}`}>
                      {data.template_id} (click to show)
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" variant="head">
                    Status:
                  </TableCell>
                  <TableCell variant="body">{data.status}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" variant="head">
                    Created at:
                  </TableCell>
                  <TableCell>{format(data.created_at, 'dd.MM.yyyy HH:mm')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" variant="head">
                    Updated at:
                  </TableCell>
                  <TableCell>{format(data.updated_at, 'dd.MM.yyyy HH:mm')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" variant="head">
                    From email:
                  </TableCell>
                  <TableCell>{data.from_email ?? '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" variant="head">
                    To email:
                  </TableCell>
                  <TableCell>{data.to_email.join('\n')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" variant="head">
                    Original emails:
                  </TableCell>
                  <TableCell>{data.original_emails.join('\n')}</TableCell>
                </TableRow>
                {errors && (
                  <TableRow>
                    <TableCell width="30%" variant="head">
                      Errors:
                    </TableCell>
                    <TableCell>
                      <Box sx={{ backgroundColor: 'ThreeDFace', overflow: 'auto', maxWidth: '100%' }} py={1} px={2}>
                        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                          {JSON.stringify(errors, null, 2)}
                        </pre>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Box>
        {renders && (
          <>
            <Box mt={3}>
              <Typography variant="overline">Subject</Typography>
              <Paper sx={{ p: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: renders.subject }} />
              </Paper>
            </Box>
            <Box mt={3}>
              <Typography variant="overline">Text</Typography>
              <Paper sx={{ p: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: renders.text }} />
              </Paper>
            </Box>
            <Box mt={3}>
              <Typography variant="overline">HTML</Typography>
              <Paper sx={{ p: 2 }}>
                <div dangerouslySetInnerHTML={{ __html: renders.html }} />
              </Paper>
            </Box>
          </>
        )}
      </SidePageLayout>
    </DrawerPage>
  );
};

export default OperationEmailLog;
