import { type FC, Suspense } from 'react';
import { DrawerPage } from 'components';
import TemplateForm from 'features/templates/components/TemplateForm';

const TemplateCreateRoute: FC = () => {
  return (
    <DrawerPage halfSize>
      <Suspense>
        <TemplateForm />
      </Suspense>
    </DrawerPage>
  );
};

export default TemplateCreateRoute;
