import { ContentCopy } from '@mui/icons-material';
import { Box, Chip, IconButton, Stack, Tooltip, Typography, styled } from '@mui/material';
import { isArray, isObject, isString } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useMemo, type FC } from 'react';
import { copyTextToClipboard } from 'utils/copyToClipboard';

const ChipStyled = styled(Chip)`
  opacity: 0.5;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`;

const stringify = (s: string | number | object) => JSON.stringify(s);

const KeyValuePrettifier: FC<{ data: string | Record<string, string | number> }> = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();

  const pairs = useMemo(() => {
    if (isString(data)) {
      return Object.entries<string | number>(JSON.parse(data));
    } else {
      return Object.entries<string | number>(data);
    }
  }, [data]);

  const copy = (str: string) => {
    copyTextToClipboard(str);
    enqueueSnackbar('Copied!', {
      variant: 'success',
    });
  };

  return (
    <Stack gap={1}>
      {pairs.map(([k, v]) => {
        return (
          <Box key={k}>
            {isObject(v) && !isArray(v) ? (
              <Stack gap={1} alignItems="start">
                {Object.entries<string | number>(v).map(([ki, vi]) => (
                  <Tooltip
                    key={ki}
                    placement="right"
                    components={{ Tooltip: Box }}
                    title={
                      <IconButton title="Copy value" size="small" onClick={() => copy(`${stringify(vi)}`)}>
                        <ContentCopy fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ChipStyled
                      size="small"
                      label={
                        <Typography variant="body2" title={`${stringify(vi)}`}>
                          <b>{ki}</b>: {stringify(vi)}
                        </Typography>
                      }
                    />
                  </Tooltip>
                ))}
              </Stack>
            ) : (
              <Tooltip
                placement="right"
                components={{ Tooltip: Box }}
                title={
                  <IconButton title="Copy value" size="small" onClick={() => copy(`${stringify(v)}`)}>
                    <ContentCopy fontSize="small" />
                  </IconButton>
                }
              >
                <ChipStyled
                  size="small"
                  label={
                    <Typography variant="body2" title={`${stringify(v)}`}>
                      <b>{k}</b>: {stringify(v)}
                    </Typography>
                  }
                />
              </Tooltip>
            )}
          </Box>
        );
      })}
    </Stack>
  );
};

export default KeyValuePrettifier;
