import theme from 'theme';
import { Titled } from 'react-titled';
import queryString from 'query-string';
import React, { Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
import { QueryParamProvider } from 'use-query-params';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CssBaseline, LinearProgress, ThemeProvider } from '@mui/material';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { queryClient } from 'lib/react-query';
import AuthContextProvider from 'lib/auth/AuthContextProvider';
import AppLayout from 'components/layouts/AppLayout/AppLayout';
import LayoutSkeleton from 'components/LayoutSkeleton/LayoutSkeleton';
import GlobalErrorBoundary from 'GlobalErrorBoundary';
import CommonStatsRoute from 'pages/stats';
import HourlyStatsRoute from 'pages/stats/hourly';
import config from 'config';
import EventDetailRoute from 'pages/events/detail';
import EventListRoute from 'pages/events';
import EventLogsRoute from 'pages/events/logs';
import RecordsStatsRoute from 'pages/stats/records';
import TemplatesListRoute from 'pages/templates';
import TemplateDetailRoute from 'pages/templates/detail';
import TemplateCreateRoute from 'pages/templates/create';
import TemplateEditRoute from 'pages/templates/edit';
import ProjectContextProvider from 'features/projects/components/ProjectContextProvider';
import RootPage from 'pages';
import ProjectSlugRoute from 'pages/projects/_slug';
import TemplatesLayoutRoute from 'pages/templates/layout';
import TemplatePlaceholdersRoute from 'pages/templates/placeholders';

const queryProviderOptions = {
  searchStringToObject: queryString.parse,
  objectToSearchString: queryString.stringify,
};

const AppProviders: React.FC = () => (
  <ThemeProvider theme={theme}>
    <QueryParamProvider adapter={ReactRouter6Adapter} options={queryProviderOptions}>
      <CssBaseline />
      <Titled title={config.title}>
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
            <GlobalErrorBoundary>
              <SnackbarProvider
                autoHideDuration={1000}
                maxSnack={5}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              >
                <Suspense
                  fallback={
                    <LayoutSkeleton>
                      <LinearProgress sx={{ mx: -3 }} />
                    </LayoutSkeleton>
                  }
                >
                  <ProjectContextProvider>
                    <AppLayout>
                      <Outlet />
                    </AppLayout>
                  </ProjectContextProvider>
                </Suspense>
              </SnackbarProvider>
            </GlobalErrorBoundary>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </AuthContextProvider>
      </Titled>
    </QueryParamProvider>
  </ThemeProvider>
);

const router = createBrowserRouter([
  {
    element: <AppProviders />,
    children: [
      { index: true, element: <RootPage to={(slug) => `/${slug}/events`} /> },
      {
        path: '/:projectSlug',
        element: <ProjectSlugRoute to={(slug) => `/${slug}/events`} />,
        children: [
          {
            path: 'stats',
            element: <Navigate to="/stats/general" replace />,
          },
          {
            path: 'stats/general',
            element: <CommonStatsRoute />,
          },
          {
            path: 'stats/hourly',
            element: <HourlyStatsRoute />,
          },
          {
            path: 'stats/records',
            element: <RecordsStatsRoute />,
          },
          {
            path: 'templates',
            element: <TemplatesLayoutRoute />,
            children: [
              {
                path: 'list',
                element: <TemplatesListRoute />,
                children: [
                  { path: 'create', element: <TemplateCreateRoute /> },
                  { path: ':templateId', element: <TemplateDetailRoute /> },
                  { path: ':templateId/edit', element: <TemplateEditRoute /> },
                ],
              },
              { path: 'placeholders', element: <TemplatePlaceholdersRoute /> },
            ],
          },
          {
            path: 'events',
            children: [
              { index: true, element: <EventListRoute /> },
              { path: ':eventId', element: <EventDetailRoute /> },
              { path: ':eventId/logs', element: <EventLogsRoute /> },
            ],
          },
          { path: '*', element: <Navigate to="/stats" replace /> },
        ],
      },
    ],
  },
]);

const App = () => (
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<div>Houston, we have a problem loading</div>} />
  </React.StrictMode>
);

export default App;
