import { type FC } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import objectTypedKeys from 'utils/objectTypedKeys';
import { TemplateTypes } from '../api/types';
import { templateTypeTranslations } from '../const/templateTypeTranslations';

const TemplateTypeSelector: FC<{
  value: string;
  onChange?: (val: string) => void;
  nativeOnChange?: (e: SelectChangeEvent) => void;
  emptyValueLabel?: string | null;
  error?: string;
  disabled?: boolean;
}> = ({ value, disabled, emptyValueLabel = 'All types', error, nativeOnChange, onChange }) => {
  const handleTypeChange = (event: SelectChangeEvent) => {
    onChange?.(event.target.value);
    nativeOnChange?.(event);
  };

  return (
    <FormControl sx={{ width: '100%' }} error={!!error}>
      <InputLabel>Types</InputLabel>
      <Select label="Types" placeholder="Types" value={value} onChange={handleTypeChange} disabled={disabled}>
        {emptyValueLabel === null ? null : <MenuItem value={undefined}>{emptyValueLabel}</MenuItem>}
        {objectTypedKeys(TemplateTypes).map((tempKey) => (
          <MenuItem key={tempKey} value={tempKey}>
            {templateTypeTranslations[tempKey]}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default TemplateTypeSelector;
