import { format } from 'date-fns';
import { useState, type FC } from 'react';
import { Box, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import type { DataEntry } from '../api/types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

interface EventUserEntriesProps {
  data: DataEntry[];
}

const EntryRow: FC<{ data: DataEntry }> = ({ data }) => {
  const [openState, setOpenState] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>{data.id}</TableCell>
        <TableCell>{data.service}</TableCell>
        <TableCell>{format(data.created_at, 'dd.MM.yyyy HH:mm')}</TableCell>
        <TableCell>{format(data.updated_at, 'dd.MM.yyyy HH:mm')}</TableCell>
        <TableCell>
          <Button
            size="small"
            onClick={() => setOpenState(!openState)}
            endIcon={openState ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          >
            Expand
          </Button>
        </TableCell>
      </TableRow>
      {openState && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={5}>
            <Box sx={{ backgroundColor: 'ThreeDFace', overflow: 'auto', maxWidth: '100%' }} padding={2}>
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                {JSON.stringify(JSON.parse(data.data), null, 2)}
              </pre>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const EventUserEntries: FC<EventUserEntriesProps> = ({ data }) => {
  return (
    <Paper>
      <Table
        stickyHeader
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Service</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Updated at</TableCell>
            <TableCell>Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((entry) => (
            <EntryRow key={entry.id} data={entry} />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default EventUserEntries;
