import type { FC } from 'react';
import { useGetTemplate } from '../api';
import { SidePageLayout } from 'components';
import { Box, Chip, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import useTypedParams from 'utils/useTypedParams';
import { templateTypeTranslations } from '../const/templateTypeTranslations';

interface TemplateDetailsProps {}

const TemplateDetails: FC<TemplateDetailsProps> = () => {
  const { templateId } = useTypedParams({ templateId: Number });
  const { data: template } = useGetTemplate(templateId);

  const emailFields: Array<[string, string]> | null = (() => {
    try {
      return template.project.email_fields ? Object.entries<string>(JSON.parse(template.project.email_fields)) : null;
    } catch {
      return null;
    }
  })();

  return (
    <SidePageLayout fullHeight title={null} name={`Template ${template.id}`}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="overline">Type / Language</Typography>
          <Stack direction="row" gap={1}>
            <Chip label={templateTypeTranslations[template.type]} />
            <Chip label={template.language} />
          </Stack>
        </Box>
        <Box>
          <Typography variant="overline">Subject</Typography>
          <Paper sx={{ p: 2 }}>{template.subject}</Paper>
        </Box>
        <Box>
          <Typography variant="overline">Text</Typography>
          <Paper sx={{ p: 2, whiteSpace: 'pre-wrap' }}>{template.text}</Paper>
        </Box>
        {template.html && (
          <Box>
            <Typography variant="overline">HTML</Typography>
            <Paper sx={{ p: 2 }}>
              <div dangerouslySetInnerHTML={{ __html: template.html }} />
            </Paper>
          </Box>
        )}
        <Box>
          <Typography variant="overline">Project details</Typography>
          <Paper sx={{ p: 2 }}>
            <Typography>{template.project.name}</Typography>
            <br />
            {emailFields && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Email fields:</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emailFields.map(([k, v]) => (
                    <TableRow key={k}>
                      <TableCell>
                        <b>{k}</b>
                      </TableCell>
                      <TableCell>{v}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Paper>
        </Box>
      </Stack>
    </SidePageLayout>
  );
};
export default TemplateDetails;
