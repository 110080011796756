import type { FC } from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useGetEventLogs } from '../api';
import EventLogRow from './EventLogRow';

interface EventLogsProps {
  eventId: string;
}

const EventLogs: FC<EventLogsProps> = ({ eventId }) => {
  const { data } = useGetEventLogs(eventId);

  return (
    <Paper>
      <Table
        stickyHeader
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead>
          <TableRow>
            <TableCell width={100}>ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell width={400}>Message</TableCell>
            <TableCell width={150}>Created At</TableCell>
            <TableCell>Step ID</TableCell>
            <TableCell>Operation ID</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Executed</TableCell>
            <TableCell>Skipped</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.logs.map((log, index) => (
            <EventLogRow key={index} data={log} />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default EventLogs;
