import { rgba } from 'polished';
import { v4 as uuid } from 'uuid';
import { useMemo, type FC, useState } from 'react';
import { Alert, Box, LinearProgress, Paper, styled } from '@mui/material';
import ProjectDataPlaceholder, { ProjectDataPlaceholderModes } from './ProjectDataPlaceholder';
import { useUpdateProject } from '../api';
import { isAxiosError } from 'axios';
import { useProjectContext } from '.';

interface ProjectRowProps {}

const LinearProgressPositioned = styled(LinearProgress)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const LoadingOverlay = styled(Box)`
  background-color: ${({ theme }) => rgba(theme.palette.background.paper, 0.5)};
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ProjectPlaceholdersForm: FC<ProjectRowProps> = () => {
  const { project } = useProjectContext();
  const [error, setError] = useState<string | null>(null);

  const { mutateAsync, isPending } = useUpdateProject({ slug: project.slug });

  const mutateAsyncWrapper: typeof mutateAsync = async (x) => {
    try {
      await mutateAsync(x);
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e.response?.data?.error;
        if (message) setError(message);
      }
    }
  };

  const initialFields = useMemo(() => {
    if (!project.email_fields) return [];

    return Object.entries<string>(JSON.parse(project.email_fields)).map(([k, v]) => ({
      id: uuid(),
      name: k,
      value: v,
    }));
  }, [project.email_fields]);

  const handleProjectModify = async (
    tempId: string | undefined,
    k: string,
    v: string,
    mode: ProjectDataPlaceholderModes
  ) => {
    setError(null);

    if (mode === ProjectDataPlaceholderModes.create) {
      await mutateAsyncWrapper(
        JSON.stringify({
          ...(project.email_fields ? JSON.parse(project.email_fields) : null),
          [k]: v,
        })
      );
    }

    if (mode === ProjectDataPlaceholderModes.update) {
      const updateKey = initialFields.find(({ id }) => id === tempId)?.name;

      await mutateAsyncWrapper(
        JSON.stringify({
          ...(project.email_fields ? JSON.parse(project.email_fields) : null),
          // Deleting previous item
          ...(updateKey ? { [updateKey]: undefined } : null),
          // Creating new one
          [k]: v,
        })
      );
    }

    if (mode === ProjectDataPlaceholderModes.delete) {
      await mutateAsyncWrapper(
        JSON.stringify({
          ...(project.email_fields ? JSON.parse(project.email_fields) : null),
          [k]: undefined,
        })
      );
    }
  };

  return (
    <Paper sx={{ p: 2, position: 'relative' }}>
      {isPending && (
        <>
          <LinearProgressPositioned />
          <LoadingOverlay />
        </>
      )}

      {error && <Alert severity="error">{error}</Alert>}

      {initialFields.map(({ id, name, value }) => (
        <ProjectDataPlaceholder key={id} data={{ id, name, value }} onChange={handleProjectModify} />
      ))}

      <ProjectDataPlaceholder onChange={handleProjectModify} />
    </Paper>
  );
};

export default ProjectPlaceholdersForm;
