import { Suspense, type FC } from 'react';
import { ListSkeleton, PageLayout, TableListErrorBoundary } from 'components';
import { EventLogs } from 'features/events';
import { useParams } from 'react-router';

const EventLogsRoute: FC = () => {
  const { eventId } = useParams();
  return (
    <PageLayout to={{ pathname: './..' }} name={`Event ${eventId} Logs`}>
      <TableListErrorBoundary>
        <Suspense
          fallback={
            <ListSkeleton
              titles={[
                //
                { field: 'ID', width: 100 },
                { field: 'Status' },
                { field: 'Message', width: 400 },
                { field: 'Created At', width: 150 },
                { field: 'Step ID' },
                { field: 'Operation ID' },
                { field: 'Data' },
                { field: 'Executed' },
                { field: 'Skipped' },
              ]}
              size={10}
            />
          }
        >
          <EventLogs eventId={eventId!} />
        </Suspense>
      </TableListErrorBoundary>
    </PageLayout>
  );
};

export default EventLogsRoute;
