import type { To} from 'react-router';
import { useLocation, useNavigate } from 'react-router';

export const useGoBack = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const doesAnyHistoryEntryExist = location.key !== 'default';

  return (navigateFallbackPath: To) => {
    if (doesAnyHistoryEntryExist) {
      navigate(-1);
    } else {
      navigate(navigateFallbackPath);
    }
  };
};
