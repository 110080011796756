import { Suspense, type FC } from 'react';
import { PageLayout } from 'components';
import { EventDetail } from 'features/events';
import { useParams } from 'react-router';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { List as ListIcon } from '@mui/icons-material';
import EventDetailSkeleton from 'features/events/components/EventDetailSkeleton';

const EventDetailRoute: FC = () => {
  const { eventId } = useParams();
  return (
    <PageLayout
      to=".."
      name={`Event ${eventId}`}
      headerActions={
        <Button variant="contained" component={Link} to="logs" startIcon={<ListIcon />}>
          Logs
        </Button>
      }
    >
      <Suspense fallback={<EventDetailSkeleton />}>
        <EventDetail eventId={eventId!} />
      </Suspense>
    </PageLayout>
  );
};

export default EventDetailRoute;
