import type { FC } from 'react';
import { useState } from 'react';
import { Check as CheckIcon, Delete, Undo } from '@mui/icons-material';
import { Box, Button, Stack, TextField } from '@mui/material';

export enum ProjectDataPlaceholderModes {
  create,
  update,
  delete,
}

type ProjectDataPlaceholderProps = {
  data?: { id: string; name: string; value: string };
  onChange: (id: string | undefined, key: string, value: string, mode: ProjectDataPlaceholderModes) => void;
};

const ProjectDataPlaceholder: FC<ProjectDataPlaceholderProps> = ({ data, onChange }) => {
  const [name, setName] = useState<string>(data?.name ?? '');
  const [value, setValue] = useState<string>(data?.value ?? '');

  const isUpdateMode = Boolean(data);
  const isDirty = isUpdateMode && (data?.name !== name || data?.value !== value);

  const isValid = name && value;

  const handleUndo = () => {
    if (data) {
      setName(data.name);
      setValue(data.value);
    }
  };

  const handleCreate = async () => {
    if (!isValid) return;

    await onChange(
      data?.id,
      name,
      value,
      data ? ProjectDataPlaceholderModes.update : ProjectDataPlaceholderModes.create
    );

    if (!isUpdateMode) {
      setName('');
      setValue('');
    }
  };

  const handleDelete = async () => {
    await onChange(data?.id, name, value, ProjectDataPlaceholderModes.delete);
  };

  return (
    <Box p={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <TextField
          size="small"
          value={name}
          placeholder="Key"
          inputProps={{ maxlength: '256', style: isUpdateMode ? { fontWeight: 'bold' } : {} }}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          placeholder="Value"
          size="small"
          value={value}
          inputProps={{ maxlength: '256' }}
          onChange={(e) => setValue(e.target.value)}
        />
        {isUpdateMode ? (
          <Button onClick={handleCreate} disabled={!isValid || !isDirty} startIcon={<CheckIcon />}>
            Update
          </Button>
        ) : (
          <Button onClick={handleCreate} disabled={!isValid} startIcon={<CheckIcon />}>
            Create
          </Button>
        )}
        {isUpdateMode && (
          <>
            {isDirty ? (
              <Button onClick={handleUndo} startIcon={<Undo />}>
                Reset
              </Button>
            ) : (
              <Button color="error" onClick={handleDelete} startIcon={<Delete />}>
                Delete
              </Button>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default ProjectDataPlaceholder;
