import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useGetSkeletonArray } from 'hooks';
import type { FC } from 'react';
import { useMemo } from 'react';

interface ListSkeletonProps {
  size?: number;
  typographyMode?: boolean;
  titles: { field: string; width?: string | number }[];
}

const TypographySkeleton: FC = () => (
  <Typography>
    <Skeleton />
  </Typography>
);

const ListSkeleton: FC<ListSkeletonProps> = ({ size, titles, typographyMode }) => {
  const items = useGetSkeletonArray(size);
  const columns = useMemo(() => [...titles], [titles]);

  return (
    <>
      <TableContainer sx={{ flexGrow: 1 }}>
        <Table
          stickyHeader
          sx={(theme) => ({
            [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
          })}
        >
          <TableHead>
            <TableRow>
              {columns.map(({ field, width }, index) => (
                <TableCell key={index} variant="head" component="th" sx={{ backgroundColor: 'white', width }}>
                  {field}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(({ id }) => (
              <TableRow key={id}>
                {columns.map((_, index) => (
                  <TableCell key={index}>{typographyMode ? <TypographySkeleton /> : <Skeleton />}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ListSkeleton;
