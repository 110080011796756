export enum EventType {
  FullDelete = 'full_delete',
  WebDelete = 'web_delete',
  FullExport = 'full_export',
}

export enum EventAction {
  Collect = 'collect',
  Delete = 'delete',
  Export = 'export',
  Notify = 'notify',
  Suspend = 'suspend',
}

export enum EventStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum LogStatus {
  Executed = 'executed',
  Failed = 'failed',
  Skipped = 'skipped',
}

export enum EventSource {
  App = 'app',
  Web = 'web',
}

export enum EventRequestChannel {
  Ios = 'ios',
  Android = 'android',
  Web = 'web',
  Support = 'support',
}
