import type { FC } from 'react';
import { isString } from 'lodash-es';
import { Titled } from 'react-titled';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled, Box, IconButton, Typography, css } from '@mui/material';
import { Link, type To } from 'react-router-dom';

interface SidePageLayoutProps {
  backTo?: To;
  fullHeight?: boolean;
  children: React.ReactNode;
  headerSidebar?: React.ReactNode;
  footer?: React.ReactNode;
  title?: string | null;
  name: React.ReactNode;
  closeButton?: React.ReactNode;
}

const Container = styled(Box)<{ fullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100vh;
    `};
`;

const Header = styled('header')`
  display: flex;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

const Content = styled(Box)`
  padding: 24px;
  flex-grow: 1;
  overflow: auto;
`;

const Footer = styled('footer')`
  align-items: center;
  background-color: white;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
  display: flex;
  padding: 24px;
  width: 100%;
`;

const SidePageLayout: FC<SidePageLayoutProps> = ({
  backTo = '..',
  children,
  fullHeight = false,
  headerSidebar = null,
  footer = null,
  name,
  title,
  closeButton,
}) => {
  const pageTitle = (() => {
    if (isString(title)) return title;
    /** Fallback value */
    if (isString(name)) return name;
    return '';
  })();

  return (
    <>
      {title !== null && <Titled title={(rest) => `${pageTitle} - ${rest}`} />}

      <Container fullHeight={fullHeight}>
        <Header>
          {closeButton || closeButton === null ? (
            closeButton
          ) : (
            <IconButton component={Link} to={backTo}>
              <CloseIcon />
            </IconButton>
          )}
          <Typography variant="h5" fontWeight={isString(name) ? 'medium' : ''} sx={{ ml: 2 }}>
            {name}
          </Typography>
          {headerSidebar}
        </Header>
        <Content>{children}</Content>
        {footer && <Footer>{footer}</Footer>}
      </Container>
    </>
  );
};

export default SidePageLayout;
