import type { FC } from 'react';
import type { Dataset } from '../api/types';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface StackedBarProps {
  title: string;
  dataset: Dataset[];
}

const StackedBar: FC<StackedBarProps> = ({ title, dataset }) => {
  return (
    <Bar
      key={title}
      datasetIdKey={title}
      data={{ datasets: dataset }}
      options={{
        plugins: { title: { display: true, text: title } },
        responsive: true,
        scales: { x: { stacked: true }, y: { stacked: true } },
        parsing: { xAxisKey: 'key', yAxisKey: 'count' },
      }}
    />
  );
};

export default StackedBar;
