import { apiClient } from 'api';
import type { HourlyStatsDTO, RecordsStatsDTO, StatsDTO, StatsQueryParams } from './types';
import { useSuspenseQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';

export const getStats = async (params: StatsQueryParams, signal?: AbortSignal): Promise<StatsDTO> => {
  const res = await apiClient.get<StatsDTO>('/admin/stats', { params, signal });

  return res.data;
};

export const getHourlyStats = async (params: StatsQueryParams, signal?: AbortSignal): Promise<HourlyStatsDTO> => {
  const res = await apiClient.get<HourlyStatsDTO>('/admin/stats/hourly', { params, signal });
  return res.data;
};

export const getRecordsStats = async (params: StatsQueryParams, signal?: AbortSignal): Promise<RecordsStatsDTO> => {
  const res = await apiClient.get<RecordsStatsDTO>('/admin/stats/records', { params, signal });

  return res.data;
};

export type UseStatsQueryParams = {
  params: StatsQueryParams;
};

export const useGetStats = ({ params }: UseStatsQueryParams) => {
  return useSuspenseQuery({
    retry: false,
    queryFn: ({ signal }) => getStats(params, signal),
    queryKey: queryKeys.stats(params),
  });
};

export const useGetHourlyStats = ({ params }: UseStatsQueryParams) => {
  return useSuspenseQuery({
    retry: false,
    queryFn: ({ signal }) => getHourlyStats(params, signal),
    queryKey: queryKeys.hourly(params),
  });
};

export const useGetRecordsStats = ({ params }: UseStatsQueryParams) => {
  return useSuspenseQuery({
    retry: false,
    queryFn: ({ signal }) => getRecordsStats(params, signal),
    queryKey: queryKeys.records(params),
  });
};
