import type { FC } from 'react';
import { SidePageLayout } from 'components';
import { Box, Chip, Paper, Skeleton, Stack, Typography } from '@mui/material';

interface TemplateSkeletonProps {}

const TemplateSkeleton: FC<TemplateSkeletonProps> = () => {
  return (
    <SidePageLayout fullHeight title={null} name={<Skeleton width={200} />}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="overline">Type / Language</Typography>
          <Stack direction="row" gap={1}>
            <Chip label={<Skeleton width={180} />} />
            <Chip label={<Skeleton width={10} />} />
          </Stack>
        </Box>
        <Box>
          <Typography variant="overline">Subject</Typography>
          <Paper sx={{ p: 2 }}>
            <Skeleton />
          </Paper>
        </Box>
        <Box>
          <Typography variant="overline">Text</Typography>
          <Paper sx={{ p: 2 }}>
            <Skeleton height={100} variant="rectangular" />
          </Paper>
        </Box>
        <Box>
          <Typography variant="overline">HTML</Typography>
          <Paper sx={{ p: 2 }}>
            <Skeleton height={200} variant="rectangular" />
          </Paper>
        </Box>
      </Stack>
    </SidePageLayout>
  );
};
export default TemplateSkeleton;
