import { encode } from 'js-base64';
import { useEffect, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCreateTemplate, useGetTemplateSync, useUpdateTemplate } from '../api';
import { SidePageLayout } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Box,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import useTypedParams from 'utils/useTypedParams';
import type { TemplatePayload } from '../api/types';
import LocaleSelector from './LocaleSelector';
import TemplateTypeSelector from './TemplateTypeSelector';
import { useQueryParams } from 'use-query-params';
import ProjectSelector from 'features/projects/components/ProjectSelector';
import { useGetProjects } from 'features/events';
import { LoadingButton } from '@mui/lab';
import { useMatch } from 'react-router';
import { useGoBack } from 'utils/useGoBack';
import { rgba } from 'polished';
import { templateFormValidationSchema } from '../validation/schema';
import { isAxiosError } from 'axios';

const LinearProgressPositioned = styled(LinearProgress)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const LoadingOverlay = styled(Box)`
  background-color: ${({ theme }) => rgba(theme.palette.background.paper, 0.5)};
  position: absolute;
  z-index: 2;
  top: 4px;
  right: 0;
  bottom: 0;
  left: 0;
`;

interface TemplateFormProps {}

const TemplateForm: FC<TemplateFormProps> = () => {
  const goBack = useGoBack();
  const isEdit = Boolean(useMatch(':projectSlug/templates/list/:templateId/edit'));

  const { data: projects } = useGetProjects();
  const { templateId } = useTypedParams({ templateId: Number });
  const [queries] = useQueryParams(['language', 'type', 'project']);

  const {
    data: template,
    isFetched,
    isLoading,
  } = useGetTemplateSync(templateId, {
    config: { enabled: !!templateId },
  });

  const { mutateAsync: createAsync, isPending: isCreating } = useCreateTemplate();
  const { mutateAsync: updateAsync, isPending: isUpdating } = useUpdateTemplate({ id: templateId });

  const {
    control,
    watch,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<TemplatePayload>({
    mode: 'all',
    resolver: yupResolver(templateFormValidationSchema),
    defaultValues: {
      language: queries.language ?? '',
      type: queries.type ?? '',
      project: queries.project ?? '',
      subject: '',
      text: '',
      html: '',
    },
  });

  useEffect(() => {
    if (isFetched && template) {
      setValue('language', template.language);
      setValue('type', template.type);
      setValue('project', template.project.slug);
      setValue('subject', template.subject);
      setValue('text', template.text);
      setValue('html', template.html ?? '');
    }
  }, [template, isFetched]);

  const projectSlug = watch('project');

  const emailFields: Array<[string, string]> | null = (() => {
    try {
      const projectEmailFields = projects.find((p) => p.slug === projectSlug)?.email_fields;
      if (!projectEmailFields) return null;

      return Object.entries<string>(JSON.parse(projectEmailFields));
    } catch {
      return null;
    }
  })();

  const onSubmit = async (data: TemplatePayload) => {
    if (data.html) {
      // HTML string is forbidden by firewall to be sent
      data.html = encode(data.html);
    }

    try {
      if (isEdit) {
        await updateAsync(data);
      } else {
        await createAsync(data);
      }

      goBack('/templates/');
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e.response?.data?.error;
        if (message) setError('root', { message });
      }
    }
  };

  return (
    <SidePageLayout
      fullHeight
      title={null}
      name={isEdit ? `Editing a template ${templateId}` : 'Creating a template'}
      footer={
        <Stack alignSelf="end">
          {isEdit ? (
            <LoadingButton variant="contained" loading={isUpdating} type="submit" form="template-form">
              Edit
            </LoadingButton>
          ) : (
            <LoadingButton variant="contained" loading={isCreating} type="submit" form="template-form">
              Create
            </LoadingButton>
          )}
        </Stack>
      }
    >
      <Stack spacing={2}>
        {isLoading && (
          <>
            <LinearProgressPositioned />
            <LoadingOverlay />
          </>
        )}
        <form id="template-form" onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2}>
            {errors.root && (
              <Alert severity="error" sx={{ mt: 1 }}>
                {errors.root.message}
              </Alert>
            )}
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Subject"
                  placeholder="Subject"
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.subject}
                  helperText={errors.subject?.message}
                />
              )}
            />
            <Controller
              name="project"
              control={control}
              render={({ field, fieldState }) => (
                <ProjectSelector
                  disabled={isEdit}
                  value={field.value}
                  emptyValueLabel={null}
                  nativeOnChange={field.onChange}
                  error={fieldState.error && fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="language"
              control={control}
              render={({ field, fieldState }) => (
                <LocaleSelector
                  disabled={isEdit}
                  value={field.value}
                  emptyValueLabel={null}
                  nativeOnChange={field.onChange}
                  error={fieldState.error && fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState }) => (
                <TemplateTypeSelector
                  disabled={isEdit}
                  value={field.value}
                  emptyValueLabel={null}
                  nativeOnChange={field.onChange}
                  error={fieldState.error && fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="text"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  fullWidth
                  rows={8}
                  label="Text"
                  placeholder="Text"
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.text}
                  helperText={errors.text?.message}
                />
              )}
            />
            <Controller
              name="html"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  fullWidth
                  rows={8}
                  label="HTML"
                  placeholder="HTML"
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.html}
                  helperText={errors.html?.message}
                />
              )}
            />
            {emailFields && (
              <Box>
                <Typography variant="overline">Project details</Typography>
                <Paper sx={{ p: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>Email fields:</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {emailFields.map(([k, v]) => (
                        <TableRow key={k}>
                          <TableCell>
                            <b>{k}</b>
                          </TableCell>
                          <TableCell>{v}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Box>
            )}
          </Stack>
        </form>
      </Stack>
    </SidePageLayout>
  );
};
export default TemplateForm;
