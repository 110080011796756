import { Suspense, type FC, useMemo } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { Box, Button, Grid, Paper } from '@mui/material';
import { ListSkeleton, TableListErrorBoundary } from 'components';
import TemplateList from 'features/templates/components/TemplateList';
import { useRouteDataIsolator } from 'hooks';
import { Refresh } from '@mui/icons-material';
import LocaleSelector from 'features/templates/components/LocaleSelector';
import TemplateTypeSelector from 'features/templates/components/TemplateTypeSelector';
import { Outlet } from 'react-router';

const TemplatesListRoute: FC = () => {
  const [type, setType] = useQueryParam('type', withDefault(StringParam, ''));
  const [language, setLanguage] = useQueryParam('language', withDefault(StringParam, ''));

  const filterData = useMemo(() => ({ type, language }), [type, language]);

  const { data: filters, listQuery } = useRouteDataIsolator(
    useMemo(() => filterData, [filterData]),
    { nestedLevel: 4 }
  );

  const { isFilterApplied, resetFilters } = useMemo(
    () => ({
      isFilterApplied: Object.values(filters).some(Boolean),
      resetFilters: () => {
        setLanguage(undefined);
        setType(undefined);
      },
    }),
    [filters]
  );

  return (
    <Grid container spacing={3}>
      <Outlet context={{ listQuery }} />
      <Grid item xs={3}>
        <Box sx={{ position: 'sticky', top: 30 }}>
          <Box mt={1.5}>
            <LocaleSelector value={filters.language} onChange={setLanguage} />
          </Box>
          <Box mt={1.5}>
            <TemplateTypeSelector value={filters.type} onChange={setType} />
          </Box>
          {isFilterApplied && (
            <Box mt={1}>
              <Button startIcon={<Refresh />} fullWidth onClick={resetFilters}>
                Reset filters
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          <TableListErrorBoundary>
            <Suspense
              fallback={
                <ListSkeleton
                  typographyMode
                  titles={[
                    //
                    { field: 'ID', width: 80 },
                    { field: 'Type', width: 220 },
                    { field: 'Language' },
                    { field: 'Created at' },
                    { field: 'Updated at' },
                    { field: 'Actions', width: 140 },
                  ]}
                  size={10}
                />
              }
            >
              <TemplateList filters={filters} />
            </Suspense>
          </TableListErrorBoundary>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TemplatesListRoute;
