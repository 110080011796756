/* eslint-disable react/display-name */

import type { ComponentType, ReactNode } from 'react';
import type React from 'react';
import { Suspense } from 'react';

const withSuspense =
  <P extends object>(WrappedComponent: ComponentType<P>, fallback?: ReactNode): React.FC<P> =>
  (props: P) => (
    <Suspense fallback={fallback}>
      <WrappedComponent {...props} />
    </Suspense>
  );

export default withSuspense;
