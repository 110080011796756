import type { FC } from 'react';
import { Box } from '@mui/material';
import { useGetRecordsStats } from '../api';
import { useProjectContext } from 'features/projects';
import { StackedBar } from '.';

interface RecordsStatsProps {
  days: number;
}

const RecordsStas: FC<RecordsStatsProps> = ({ days }) => {
  const { project } = useProjectContext();
  const { data } = useGetRecordsStats({ params: { days, project: project.slug } });

  return (
    <Box>
      <StackedBar title="Records Analytics" dataset={data.records.datasets} />
      <Box mt={6} />
      <StackedBar title="Service Logs" dataset={data.service_logs.datasets} />
    </Box>
  );
};

export default RecordsStas;
