import { useState, type FC } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import type { TemplateDTO } from '../api/types';
import { AddCircle, Delete, DeleteForever, Edit, RemoveRedEye } from '@mui/icons-material';
import { CircularProgress, IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import { useDeleteTemplate } from '../api';
import { templateTypeTranslations } from '../const/templateTypeTranslations';

interface TemplateRowProps {
  data: Partial<TemplateDTO>;
}

const TemplateRow: FC<TemplateRowProps> = ({ data }) => {
  const [isDeleteConfirmed, setDeleteConfirmed] = useState(false);
  const { mutateAsync: deleteAsync, isPending: isDeleting } = useDeleteTemplate();

  return (
    <>
      <TableRow>
        <TableCell>{data.id}</TableCell>
        <TableCell>{data.type ? templateTypeTranslations[data.type] : ''}</TableCell>
        <TableCell>{data.language}</TableCell>
        {data.id ? (
          <>
            <TableCell>{data.created_at ? format(data.created_at, 'dd.MM.yy HH:mm') : '-'}</TableCell>
            <TableCell>{data.updated_at ? format(data.updated_at, 'dd.MM.yy HH:mm') : '-'}</TableCell>
          </>
        ) : (
          <TableCell align="center" colSpan={2}>
            [ The template hasn`t been created yet ]
          </TableCell>
        )}
        <TableCell align="left" sx={{ padding: 1 }}>
          <Stack direction="row" alignItems="center">
            {data.id ? (
              <>
                <IconButton color="primary" component={Link} to={`${data.id}`}>
                  <RemoveRedEye />
                </IconButton>
                <IconButton color="primary" title="Edit" component={Link} to={`${data.id}/edit`}>
                  <Edit />
                </IconButton>
                {data.language !== 'en' && (
                  <>
                    {isDeleting ? (
                      <CircularProgress size={18} sx={{ ml: 1 }} />
                    ) : (
                      <>
                        {!isDeleteConfirmed ? (
                          <IconButton color="error" title="Delete" onClick={() => setDeleteConfirmed(true)}>
                            <Delete />
                          </IconButton>
                        ) : (
                          <Tooltip title="Click again to confirm" placement="left">
                            <IconButton
                              color="error"
                              title="Delete"
                              onClick={() => deleteAsync(data.id!)}
                              onMouseLeave={() => setDeleteConfirmed(false)}
                            >
                              <DeleteForever />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <IconButton
                color="primary"
                title="Edit"
                component={Link}
                to={{
                  pathname: 'create',
                  search: `?language=${data.language}&type=${data.type}&project=${data.project?.slug}`,
                }}
              >
                <AddCircle />
              </IconButton>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TemplateRow;
