import { apiClient } from 'api';
import queryKeys from './queryKeys';
import type { TemplateDTO, TemplatePayload, TemplatesParams } from './types';
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import type { MutationConfig, QueryConfig } from 'lib/react-query';

export const getTemplates = async (params: TemplatesParams, signal?: AbortSignal) => {
  const res = await apiClient.get<TemplateDTO[]>('/admin/templates', { params, signal });
  return res.data;
};

export const getTemplate = async (templateId: number, signal?: AbortSignal) => {
  const res = await apiClient.get<TemplateDTO>(`/admin/templates/${templateId}`, { signal });
  return res.data;
};

export const updateTemplate = async (id: number, data: TemplatePayload) => {
  await apiClient.patch(`/admin/templates/${id}`, data);
};

export const createTemplate = async (data: TemplatePayload) => {
  await apiClient.post(`/admin/templates/`, data);
};

export const deleteTemplate = async (id: number) => {
  await apiClient.delete(`/admin/templates/${id}`);
};

type UseGetTemplateOptions = {
  config?: QueryConfig<() => ReturnType<typeof getTemplate>>;
};

export const useGetTemplate = (id: number, { config }: UseGetTemplateOptions = {}) => {
  return useSuspenseQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getTemplate(id, signal),
    queryKey: queryKeys.templateDetails(id),
  });
};

export const useGetTemplateSync = (id: number, { config }: UseGetTemplateOptions = {}) => {
  return useQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getTemplate(id, signal),
    queryKey: queryKeys.templateDetails(id),
  });
};

type UseGetTemplatesOptions = {
  config?: QueryConfig<() => ReturnType<typeof getTemplates>>;
  params: TemplatesParams;
};

export const useGetTemplates = ({ config, params }: UseGetTemplatesOptions) => {
  return useSuspenseQuery({
    retry: false,
    ...config,
    queryFn: ({ signal }) => getTemplates(params, signal),
    queryKey: queryKeys.templatesList(params),
  });
};

type UseUpdateTemplateOptions = {
  id: number;
  config?: MutationConfig<(data: TemplatePayload) => ReturnType<typeof updateTemplate>>;
};

export const useUpdateTemplate = ({ id, config }: UseUpdateTemplateOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.templatesList() });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: (data: TemplatePayload) => updateTemplate(id, data),
  });
};

type UseCreateTemplateOptions = {
  config?: MutationConfig<(data: TemplatePayload) => ReturnType<typeof createTemplate>>;
};

export const useCreateTemplate = ({ config }: UseCreateTemplateOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.templatesList() });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: (data: TemplatePayload) => createTemplate(data),
  });
};

type UseDeleteTemplateOptions = {
  config?: MutationConfig<(id: number) => ReturnType<typeof deleteTemplate>>;
};

export const useDeleteTemplate = ({ config }: UseDeleteTemplateOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.templatesList() });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: (id: number) => deleteTemplate(id),
  });
};
