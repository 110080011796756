import { type FC } from 'react';
import { Navigate } from 'react-router';
import { useGetProjects } from 'features/events';

const RootPage: FC<{ to: (slug: string) => string }> = ({ to }) => {
  const { data: projects } = useGetProjects();
  const projectSlug = projects[0]?.slug;

  if (projectSlug) {
    return <Navigate to={to(projectSlug)} replace />;
  } else {
    throw new Error("There's not project created");
  }
};

export default RootPage;
