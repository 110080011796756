import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { SwipeableDrawer, useTheme } from '@mui/material';

interface DrawerPageProps {
  halfSize?: boolean;
  skipNavagation?: boolean;
  children: ReactNode;
  onClose?: () => void;
}

const DrawerPage: FC<DrawerPageProps> = ({ children, skipNavagation, halfSize, onClose }) => {
  const outletContext = useOutletContext<null | { listQuery: URLSearchParams }>();
  const listQuery = outletContext?.listQuery;

  const theme = useTheme();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState<boolean>(true);

  const handleOpen = useCallback(() => {
    /* do nothing */
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);

    setTimeout(() => {
      onClose?.();

      if (skipNavagation) return;

      navigate({
        pathname: '..',
        search: listQuery ? listQuery.toString() : '',
      });
    }, theme.transitions.duration.leavingScreen + 1);
  }, [setOpen, navigate, listQuery, theme.transitions.duration.leavingScreen]);

  return (
    <SwipeableDrawer
      anchor="right"
      variant="temporary"
      sx={{
        flexShrink: 0,
        maxHeight: '100vh',
        [theme.breakpoints.down('md')]: {
          zIndex: theme.zIndex.appBar - 2,
        },
      }}
      PaperProps={{
        sx: {
          height: 'unset',
          position: 'absolute',
          bottom: 0,
          backgroundColor: '#F9FAFC',
          width: '100%',
          maxWidth: halfSize ? '50%' : '33.33%',
          [theme.breakpoints.down('md')]: {
            top: 65,
            maxWidth: '66.66%',
          },
          [theme.breakpoints.down('sm')]: {
            top: 57,
            maxWidth: 'none',
          },
        },
      }}
      SlideProps={{
        appear: true,
      }}
      componentsProps={{
        backdrop: {
          sx: {
            [theme.breakpoints.down('md')]: {
              top: 65,
            },
            [theme.breakpoints.down('sm')]: {
              top: 57,
            },
          },
        },
      }}
      onOpen={handleOpen}
      onClose={handleClose}
      open={isOpen}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default DrawerPage;
