import type { FC } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import { EventRequestChannel } from '../types';
import { Android, Apple, Help, Language } from '@mui/icons-material';

interface EventPlatformChipProps {
  platform: EventRequestChannel;
}

const platformToLabelPairs = {
  [EventRequestChannel.Android]: 'android',
  [EventRequestChannel.Ios]: 'iOS',
  [EventRequestChannel.Support]: 'support',
  [EventRequestChannel.Web]: 'WEB',
};

const EventPlatformChip: FC<EventPlatformChipProps> = ({ platform }) => {
  return (
    <Stack alignItems="start">
      <Chip
        size="small"
        label={
          <Typography m={0} variant="body2" gap={0.5} display="flex" justifyContent="center" alignItems="center">
            {platformToLabelPairs[platform]}

            {platform === EventRequestChannel.Android && <Android fontSize="small" />}
            {platform === EventRequestChannel.Ios && <Apple fontSize="small" />}
            {platform === EventRequestChannel.Web && <Language fontSize="small" />}
            {platform === EventRequestChannel.Support && <Help fontSize="small" />}
          </Typography>
        }
      />
    </Stack>
  );
};

export default EventPlatformChip;
