import { apiClient } from 'api';
import queryKeys from './queryKeys';
import type { ProjectDTO } from './types';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';

export const getProjects = async (signal?: AbortSignal): Promise<ProjectDTO[]> => {
  const res = await apiClient.get<ProjectDTO[]>('/admin/projects', { signal });
  return res.data;
};

export const updateProject = async (slug: string, data: string) => {
  await apiClient.patch(`/admin/projects/${slug}`, { email_fields: data });
};

export const useGetProjects = () => {
  return useSuspenseQuery({
    retry: false,
    queryFn: ({ signal }) => getProjects(signal),
    queryKey: queryKeys.projectsList(),
  });
};

type UseUpdateProjectOptions = {
  slug: string;
  config?: MutationConfig<(data: string) => ReturnType<typeof updateProject>>;
};

export const useUpdateProject = ({ slug, config }: UseUpdateProjectOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.projectsList() });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: (data: string) => updateProject(slug, data),
  });
};
