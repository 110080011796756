import React from 'react';
import type { FC } from 'react';
import { format } from 'date-fns';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import type { StepDTO } from '../api/types';
import OperationRow from './OperationRow';
import { grey } from '@mui/material/colors';

interface EventOperationsProps {
  steps: StepDTO[];
}

const EventOperations: FC<EventOperationsProps> = ({ steps }) => {
  return (
    <Paper>
      <Table
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: { tableLayout: 'fixed' },
        })}
      >
        <TableHead sx={{ backgroundColor: grey[50] }}>
          <TableRow>
            <TableCell>Step ID</TableCell>
            <TableCell>Step #</TableCell>
            <TableCell>Action</TableCell>
            <TableCell align="center" colSpan={3}>
              Step Status
            </TableCell>
            <TableCell align="center" rowSpan={2} sx={{ width: 150 }}>
              Created At
            </TableCell>
            <TableCell align="center" rowSpan={2} sx={{ width: 150 }}>
              Updated At
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Operation ID</TableCell>
            <TableCell>Service</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Operation Status</TableCell>
            <TableCell>Identifier</TableCell>
            <TableCell align="center">State</TableCell>
            <TableCell sx={{ width: 150 }}>Last Excecuted</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {steps.map((step) => (
            <React.Fragment key={step.id}>
              <TableRow sx={{ bgcolor: 'rgb(235 235 235)' }}>
                <TableCell>{step.id}</TableCell>
                <TableCell>{step.order + 1}</TableCell>
                <TableCell>{step.action}</TableCell>
                <TableCell colSpan={3} align="center">
                  {step.status}
                </TableCell>
                <TableCell>{format(step.created_at, 'dd.MM.yyyy HH:mm')}</TableCell>
                <TableCell>{format(step.updated_at, 'dd.MM.yyyy HH:mm')}</TableCell>
              </TableRow>
              {step.operations.map((operation, index) => (
                <OperationRow key={index} data={operation} />
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default EventOperations;
